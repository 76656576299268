import React from "react";

export default function MaterialFacet({
  handleFilterChange,
  productMaterial,
  selectedMaterial,
}) {
  return (
    <div className="facet-wrap facet-display">
      <div className="facetwp-facet">
        <select
          value={selectedMaterial}
          onChange={(e) => {
            const selectedMaterial = e.target.value;
            handleFilterChange("material_facet", selectedMaterial);
          }}>
          <option value="">CHOOSE MATERIAL</option>
          {Object.keys(productMaterial).length > 0 &&
            Object.keys(productMaterial).map((material, i) => {
              if (productMaterial[material] > 0) {
                return (
                  <option key={i} value={material.toLowerCase()}>
                    {material} ({productMaterial[material]})
                  </option>
                );
              }
              return null;
            })}
        </select>
      </div>
    </div>
  );
}
