import React from "react";

export default function StyleFacet({
  handleFilterChange,
  productStyle,
  selectedStyle,
}) {
  return (
    <div className="facet-wrap facet-display">
      <div className="facetwp-facet">
        <select
          value={selectedStyle}
          onChange={(e) => {
            const selectedStyle = e.target.value;
            handleFilterChange("style_facet", selectedStyle);
          }}>
          <option value="">CHOOSE STYLE</option>
          {Object.keys(productStyle).length > 0 &&
            Object.keys(productStyle).map((style, i) => {
              if (style && productStyle[style] > 0) {
                return (
                  <option key={i} value={style.toLowerCase()}>
                    {style} ({productStyle[style]})
                  </option>
                );
              }
              return null;
            })}
        </select>
      </div>
    </div>
  );
}
