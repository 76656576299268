import React from "react";

export default function InstallationMethodFacet({
  handleFilterChange,
  productInstallationMethods,
  selectedInstallationMethod,
}) {
  return (
    <div className="facet-wrap facet-display">
      <div className="facetwp-facet">
        <select
          value={selectedInstallationMethod}
          onChange={(e) => {
            const selectedInstallationMethod = e.target.value;
            handleFilterChange(
              "installation_method",
              selectedInstallationMethod
            );
          }}>
          <option value="">CHOOSE INSTALLATION METHOD</option>
          {Object.keys(productInstallationMethods).length > 0 &&
            Object.keys(productInstallationMethods).map(
              (installationMethod, i) => {
                if (productInstallationMethods[installationMethod] > 0) {
                  return (
                    <option key={i} value={installationMethod.toLowerCase()}>
                      {installationMethod} (
                      {productInstallationMethods[installationMethod]})
                    </option>
                  );
                }
                return null;
              }
            )}
        </select>
      </div>
    </div>
  );
}
