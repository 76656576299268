import React, { useState, useEffect } from "react";
import "./AllianceProducts.css";
import AllianceProductDetailPage from "./components/AllianceProductDetailPage";
// import ProductDetailPageGravity from "./components/ProductDetailPageGravity"
import { useParams, useLocation } from "react-router-dom";
import Loader from "./components/Loader";

function AllianceProductDetails() {
  const [products, setProducts] = useState([]);
  const wpProductFilter = window.wpProductFilter;
  const wpProductCategory = window.wpProductCategory;
  const sku = wpProductCategory.sku;
  const location = useLocation();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (sku) {
      fetchProducts();
    }
    // fetchFilterCounts();
  }, [sku]);

  const fetchProducts = async () => {
    const query = new URLSearchParams({
      sku: wpProductCategory.sku,
      category: wpProductCategory.category,
      pageName: "pdp",
    });

    try {
      const response = await fetch(`${wpProductFilter.apiEndpoint}?${query}`);
      const data = await response.json();
      setProducts(data.products_group_by);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="product-list-container" id="alliance-pdp">
      <div className="product-detail-layout-6">
        <div class="plp_top_background"></div> 
        {loading && <Loader />}
        <div class="pdp_inner">
          {Object.keys(products)?.map((product, i) => {
            return (
              <AllianceProductDetailPage
                key={i}
                product={products[product][0]}
                type={wpProductCategory.category}
                sku={wpProductCategory.sku}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default AllianceProductDetails;
