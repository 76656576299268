import React from "react";

export default function SpeciesFacet({
  handleFilterChange,
  productSpecies,
  selectedSpecies,
}) {
  return (
    <div className="facet-wrap facet-display">
      <div className="facetwp-facet">
        <select
          value={selectedSpecies} // Set the selected species as the value
          onChange={(e) => {
            const selectedSpecies = e.target.value;
            handleFilterChange("species_facet", selectedSpecies);
          }}>
          <option value="">CHOOSE SPECIES</option>
          {Object.keys(productSpecies).length > 0 &&
            Object.keys(productSpecies).map((species, i) => {
              if (productSpecies[species] > 0) {
                return (
                  <option key={i} value={species.toLowerCase()}>
                    {species} ({productSpecies[species]})
                  </option>
                );
              }
              return null;
            })}
        </select>
      </div>
    </div>
  );
}
