import React from "react";
import Swatch from "../Swatch"; // Assuming you have Swatch component
import inStockImg from "../media/instock.png"; // Assuming you have inStock image

export default function ProductLoop({ products, visibleProducts }) {
  // const showClass = index < visibleProducts ? "show-item" : "hide-item";
  const classes = "col-lg-3 col-md-3 col-sm-6 "; // Example class names for styling

  const product = products;
  if (!product) return null;
  const siteInfo = window.wpProductFilter.siteInfo;
  const getcouponbtn = siteInfo.getcouponbtn;
  const getcouponreplace = siteInfo.getcouponreplace;
  const getcouponreplacetext = siteInfo.getcouponreplacetext;
  const getcouponreplaceurl = siteInfo.getcouponreplaceurl;
  const show_financing = siteInfo.show_financing;
  const getfinancereplace = siteInfo.getfinancereplace;
  const getcoupon_link = siteInfo.getcoupon_link;
  const showprice = siteInfo.plpshowprice;
  const plpestimatebutton = siteInfo.plpestimatebutton;
  const plpestimatebuttonlink = siteInfo.plpestimatebuttonlink;
  const arearugshowsku = siteInfo.arearugshowsku;
  const getfinancereplaceurl =
    getfinancereplace === "1"
      ? siteInfo.getfinancereplaceurl
      : "/flooring-financing/";
  const getfinancetext =
    getfinancereplace === "1" ? siteInfo.getfinancetext : "Get Financing";

  // Check if url_key exists before trying to split it
  let url_slug = product.url_key ? product.url_key : ""; // Default to empty string if url_key is undefined

  const wpProductCategory = window.wpProductCategory;
  if (
    wpProductCategory.rewrite_slug !== "" &&
    wpProductCategory.rewrite_slug !== undefined
  ) {
    const segments = url_slug.split("/");
    url_slug =
      "/" +
      wpProductCategory.rewrite_slug +
      "/" +
      segments[segments.length - 1];
  }

  url_slug += url_slug.endsWith("/") ? "" : "/";

  return (
    <div className={`alliance-item product-item ${classes}`}>
      <div className="fl-post-grid-post">
        <div className="product-main-wrp">
          <div className="fl-post-grid-image">
            <Swatch
              swatch={product.swatch}
              pdpUrl={`${window.location.origin}${url_slug}`}
              height={400}
              width={400}
            />
            {product.in_stock === 1 && (
              <img
                src={inStockImg}
                className="instockImgPlp entered"
                alt="In Stock"
              />
            )}
          </div>
          <div className="fl-post-grid-text product-grid btn-grey">
            <h4>
              <span>
                {arearugshowsku === "1"
                  ? product.sku
                  : product.collection_facet}
              </span>
              <span>{product.brand}</span>
            </h4>
            <h6>color#: {product.color_facet}</h6>

            {product.in_stock === 1 &&
              showprice === 1 &&
              product.price !== 0 && (
                <div className="price">
                  <strong>
                    <sup>$</sup>
                    {product.price}
                  </strong>
                  &nbsp;/<sub>{product.price_unit}</sub>
                </div>
              )}
            <a
              href={`${window.location.origin}${url_slug}`}
              className="fl-button plp_box_btn">
              VIEW DETAILS
            </a>

            {getcouponbtn === "1" && (
              <a href={getcoupon_link} className="link getcouponbtnlink">
                <span className="fl-button-text">GET COUPON</span>
              </a>
            )}
            {product.in_stock === 1 && plpestimatebutton === "1" && (
              <a
                href={`${plpestimatebuttonlink}?ChoiceColor=${product.color_facet}&Choicesku=${product.sku}&Choicecollection=${product.collection_facet}&Flooringtype=${product.z_prod_type}`}
                target="_self"
                className="link plpestimatelink"
                role="button">
                <span className="fl-button-text">REQUEST AN ESTIMATE</span>
              </a>
            )}
            {getcouponreplace === "1" &&
              getcouponreplaceurl !== "" &&
              getcouponreplacetext !== "" && (
                <a
                  href={getcouponreplaceurl}
                  className="linkcustompdpbtnlink d-block">
                  <span className="fl-button-text">{getcouponreplacetext}</span>
                </a>
              )}
            {show_financing === "1" && (
              <a href={getfinancereplaceurl} className="link d-block">
                <span className="fl-button-text">
                  {getfinancereplace === "1"
                    ? siteInfo.getfinancetext
                    : "Get Financing"}
                </span>
              </a>
            )}

            <div class="roomvo">
              <div className="roomvo-container">
                <a
                  className="roomvo-stimr fl-button"
                  data-sku={product.sku}
                  style={{ visibility: "hidden" }}>
                  <i className="fa fa-camera" aria-hidden="true"></i> &nbsp;See
                  In My Room
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
