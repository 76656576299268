import React, { useState, useEffect } from "react";
import "./AllianceProducts.css";
import ProductLoop from "./components/alliance/ProductLoop";
import CollectionFacet from "./components/facets/select/CollectionFacet";
import BrandFacet from "./components/facets/select/BrandFacet";
import ColorFacet from "./components/facets/select/ColorFacet";
import StyleFacet from "./components/facets/select/StyleFacet";
import ConstructionFacet from "./components/facets/select/ConstructionFacet";
import InstallationMethodFacet from "./components/facets/select/InstallationMethodFacet";
import SpeciesFacet from "./components/facets/select/SpeciesFacet";
import ShadeFacet from "./components/facets/select/ShadeFacet";
import LookFacet from "./components/facets/select/LookFacet";
import MaterialFacet from "./components/facets/select/MaterialFacet";
// import ResetFilters from "./components/facets/ResetFilters";

//import PerPage from "./components/facets/PerPage";

// import Pagination from "./components/Pagination";
import Loader from "./components/Loader";
import { useNavigate } from "react-router-dom";
import RemoveFilters from "./components/RemoveFilters";
let allFilters = "";
function AllianceProducts() {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [page, setPageValue] = useState(1);
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const wpProductFilter = window.wpProductFilter;
  const wpProductCategory = window.wpProductCategory;

  const catColor = wpProductCategory.color_facet
    ? wpProductCategory.color_facet.toLowerCase()
    : "";
  const catBrandFacet = wpProductCategory.brand_facet
    ? wpProductCategory.brand_facet.toLowerCase()
    : "";
  const catCollectionFacet = wpProductCategory.collection_facet
    ? wpProductCategory.collection_facet.toLowerCase()
    : "";
  const catStyleFacet = wpProductCategory.style_facet
    ? wpProductCategory.style_facet.toLowerCase()
    : "";
  const catShadeFacet = wpProductCategory.shade_facet
    ? wpProductCategory.shade_facet.toLowerCase()
    : "";
  const catShapeFacet = wpProductCategory.shape_facet
    ? wpProductCategory.shape_facet.toLowerCase()
    : "";
  const catFiberFacet = wpProductCategory.fiber
    ? wpProductCategory.fiber.toLowerCase()
    : "";
  const shortcodeLoadFacets = {
    color_facet: catColor,
    brand_facet: catBrandFacet,
    collection_facet: catCollectionFacet,
    style_facet: catStyleFacet,
    shade_facet: catShadeFacet,
    shape_facet: catShapeFacet,
    fiber: catFiberFacet,
  };

  let shortcodeFacets = Object.fromEntries(
    Object.entries(shortcodeLoadFacets).filter(([_, v]) => v != "")
  );

  const queryParams = new URLSearchParams(window.location.search);

  const paramColor = queryParams.get("color_facet")
    ? queryParams.get("color_facet").toLowerCase()
    : "";
  const paramBrandFacet = queryParams.get("brand_facet")
    ? queryParams.get("brand_facet").toLowerCase()
    : "";
  const paramCollectionFacet = queryParams.get("collection_facet")
    ? queryParams.get("collection_facet").toLowerCase()
    : "";
  const paramApplicationFacet = queryParams.get("application_facet")
    ? queryParams.get("application_facet").toLowerCase()
    : "";
  const paramVariationFacet = queryParams.get("color_variation_facet")
    ? queryParams.get("color_variation_facet").toLowerCase()
    : "";
  const paramConstructionFacet = queryParams.get("construction_facet")
    ? queryParams.get("construction_facet").toLowerCase()
    : "";
  const paramInstallationFacet = queryParams.get("installation_facet")
    ? queryParams.get("installation_facet").toLowerCase()
    : "";
  const paramLocationFacet = queryParams.get("location_facet")
    ? queryParams.get("location_facet").toLowerCase()
    : "";
  const paramSpeciesFacet = queryParams.get("species_facet")
    ? queryParams.get("species_facet").toLowerCase()
    : "";
  const paramTextureFacet = queryParams.get("surface_texture_facet")
    ? queryParams.get("surface_texture_facet").toLowerCase()
    : "";
  const paramStyleFacet = queryParams.get("style_facet")
    ? queryParams.get("style_facet").toLowerCase()
    : "";
  const paramShadeFacet = queryParams.get("shade_facet")
    ? queryParams.get("shade_facet").toLowerCase()
    : "";
  const paramShapeFacet = queryParams.get("shape_facet")
    ? queryParams.get("shape_facet").toLowerCase()
    : "";
  const paramLookFacet = queryParams.get("look_facet")
    ? queryParams.get("look_facet").toLowerCase()
    : "";
  const paramMaterialFacet = queryParams.get("material_facet")
    ? queryParams.get("material_facet").toLowerCase()
    : "";
  const paramFiberFacet = queryParams.get("fiber")
    ? queryParams.get("fiber").toLowerCase()
    : "";
  const paramBackingFacet = queryParams.get("backing_facet")
    ? queryParams.get("backing_facet").toLowerCase()
    : "";
  const paramThicknessFacet = queryParams.get("thickness_facet")
    ? queryParams.get("thickness_facet").toLowerCase()
    : "";
  const paramHeight = queryParams.get("height")
    ? queryParams.get("height").toLowerCase()
    : "";
  const paramWidth = queryParams.get("width")
    ? queryParams.get("width").toLowerCase()
    : "";

  const paramSampleFacet = queryParams.get("sample_available_facet")
    ? queryParams.get("sample_available_facet").toLowerCase()
    : "";

  const filterObj = {
    color_facet: paramColor ? paramColor : catColor,
    brand_facet: paramBrandFacet ? paramBrandFacet : catBrandFacet,
    collection_facet: paramCollectionFacet
      ? paramCollectionFacet
      : catCollectionFacet,
    application_facet: paramApplicationFacet,
    color_variation_facet: paramVariationFacet,
    construction_facet: paramConstructionFacet,
    installation_facet: paramInstallationFacet,
    location_facet: paramLocationFacet,
    species_facet: paramSpeciesFacet,
    surface_texture_facet: paramTextureFacet,
    style_facet: paramStyleFacet ? paramStyleFacet : catStyleFacet,
    shade_facet: paramShadeFacet ? paramShadeFacet : catShadeFacet,
    shape_facet: paramShapeFacet ? paramShapeFacet : catShapeFacet,
    look_facet: paramLookFacet,
    material_facet: paramMaterialFacet,
    fiber: paramFiberFacet ? paramFiberFacet : catFiberFacet,
    backing_facet: paramBackingFacet,
    thickness_facet: paramThicknessFacet,
    height: paramHeight,
    width: paramWidth,
    sample_available_facet: paramSampleFacet,
    search: queryParams.get("search")
      ? queryParams.get("search").toLowerCase()
      : "",
  };
  let filteredObj = Object.fromEntries(
    Object.entries(filterObj).filter(([_, v]) => v != "")
  );

  const [filters, setFilters] = useState(filteredObj);

  const filterObj1 = {
    color_facet: paramColor,
    brand_facet: paramBrandFacet,
    collection_facet: paramCollectionFacet,
    application_facet: paramApplicationFacet,
    color_variation_facet: paramVariationFacet,
    construction_facet: paramConstructionFacet,
    installation_facet: paramInstallationFacet,
    location_facet: paramLocationFacet,
    species_facet: paramSpeciesFacet,
    surface_texture_facet: paramTextureFacet,
    style_facet: paramStyleFacet,
    shade_facet: paramShadeFacet,
    shape_facet: paramShapeFacet,
    look_facet: paramLookFacet,
    material_facet: paramMaterialFacet,
    fiber: paramFiberFacet,
    backing_facet: paramBackingFacet,
    thickness_facet: paramThicknessFacet,
    height: paramHeight,
    width: paramWidth,
    sample_available_facet: paramSampleFacet,
    paged: queryParams.get("paged") ? queryParams.get("paged") : "",
    limit: queryParams.get("limit") ? queryParams.get("limit") : "",
    search: queryParams.get("search")
      ? queryParams.get("search").toLowerCase()
      : "",
  };
  let filteredObj1 = Object.fromEntries(
    Object.entries(filterObj1).filter(([_, v]) => v != "")
  );
  const [urlParams, setUrlParams] = useState(filteredObj1);
  // const [sort, setSort] = useState("");
  const paramPage = queryParams.get("paged") ? queryParams.get("paged") : 1;
  const [paged, setPage] = useState(paramPage);
  const [productCollections, setCollections] = useState({});
  const [productBrands, setBrands] = useState({});
  const [productColors, setColors] = useState({});
  // const [productApplications, setApplications] = useState({});
  const [productConstructions, setConstruction] = useState({});
  // const [productColorVariations, setColorVariation] = useState({});
  const [productInstallationMethods, setInstallationMethods] = useState({});
  // const [productLocations, setLocations] = useState({});
  const [productSpecies, setSpecies] = useState({});
  // const [productTextures, setTextures] = useState({});
  const [productStyle, setStyle] = useState({});
  const [productShade, setShade] = useState({});
  // const [productShape, setShape] = useState({});
  const [productLook, setLook] = useState({});
  const [productMaterial, setMaterial] = useState({});
  // const [productFiber, setFiber] = useState({});
  // const [productBacking, setBacking] = useState({});
  // const [productThickness, setThickness] = useState({});
  // const [productHeight, setHeight] = useState({});
  // const [productWidth, setWidth] = useState({});
  // const [productSamples, setSamples] = useState({});
  const [visibleProducts, setVisibleProducts] = useState(20);
  // const [pageNumbers, setPageNumbers] = useState([]);
  const paramLimit = queryParams.get("limit") ? queryParams.get("limit") : 20;
  const [itemsPerPage, setItemsPerPage] = useState(paramLimit);

  // const paramSearch = queryParams.get("search")
  //   ? queryParams.get("search").toLowerCase()
  //   : "";
  // const [searchText, setSearchText] = useState(paramSearch);

  const inStock = wpProductCategory.inStock ? wpProductCategory.inStock : "";
  const plpTitle = wpProductCategory.title ? wpProductCategory.title : "";
  const plpSubTitle = wpProductCategory.subTitle
    ? wpProductCategory.subTitle
    : "";
  const pageName = wpProductCategory.page ? wpProductCategory.page : "";

  const allFacets = wpProductCategory.plpFacets
    ? wpProductCategory.plpFacets
    : [];
  const collectionFacets = wpProductCategory.collectionFacets
    ? wpProductCategory.collectionFacets
    : [];

  const plpFacets = pageName == "collection" ? collectionFacets : allFacets;

  const [loading, setLoading] = useState(true);
  //fetch products

  const fetchProducts = async () => {
    const query = new URLSearchParams({
      category: wpProductCategory.category,
      ...filters,
      paged: page,
      limit: 400,
      in_stock: inStock,
      group_by: "sku",
    });

    const query1 = new URLSearchParams({
      ...urlParams,
    });

    try {
      navigate("?" + query1.toString());
      const response = await fetch(`${wpProductFilter.apiEndpoint}?${query}`);

      if (!response.ok) {
        const errorData = await response.json();
        console.error("API Error:", errorData);
        return;
      }
      const data = await response.json();
      const allProducts = Array.isArray(data.products_group_by)
        ? data.products_group_by.flat()
        : data.products_group_by && typeof data.products_group_by === "object"
        ? Object.values(data.products_group_by).flat()
        : [];

      allFilters = allFilters !== "" ? allFilters : data.filter_counts;

      setProducts(allProducts);
      let trimedProducts = allProducts.slice(0, visibleProducts);
      setDisplayedProducts(trimedProducts);
      setTotalProducts(data.total);
      populateFilters(allFilters);
      setVisibleProducts(20);
      // calculatePagination(data.total, itemsPerPage, paged);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [filters, itemsPerPage]);

  // const productArray = Array.isArray(products) ? products : [];
  // const displayedProducts = productArray.slice(0, visibleProducts);

  const toggleFlyer = () => {
    setIsOpen(!isOpen);
  };

  const populateFilters = (counts) => {
    setCollections(counts["collection_facet"]);
    setBrands(counts["brand_facet"]);
    setColors(counts["color_facet"]);
    // setApplications(counts["application_facet"]);
    // setColorVariation(counts["color_variation_facet"]);
    setConstruction(counts["construction_facet"]);
    setInstallationMethods(counts["installation_facet"]);
    // setLocations(counts["location_facet"]);
    setSpecies(counts["species_facet"]);
    // setTextures(counts["surface_texture_facet"]);
    setStyle(counts["style_facet"]);
    setShade(counts["shade_facet"]);
    // setShape(counts["shape_facet"]);
    setLook(counts["look_facet"]);
    setMaterial(counts["material_facet"]);
    // setFiber(counts["fiber"]);
    // setBacking(counts["backing_facet"]);
    // setHeight(counts["height"]);
    // setWidth(counts["width"]);
    // setThickness(counts["thickness_facet"]);
    // setSamples(counts["sample_available_facet"]);
  };

  //load more button

  const loadMore = (offset) => {
    if (visibleProducts < totalProducts) {
      setVisibleProducts(offset);
      setDisplayedProducts(products.slice(0, offset));
    }
  };

  const handleFilterChange = (filterName, value) => {
    setFilters({
      ...shortcodeFacets,
      ...filters,
      [filterName]: value.toLowerCase(),
    });

    setPage(1);

    setUrlParams({
      ...urlParams,
      [filterName]: value.toLowerCase(),
      paged: 1,
    });
  };

  // const handleRemoveFilter = (filterName) => {
  //   const updatedObj = Object.fromEntries(
  //     Object.entries(filters).filter(([key]) => key !== filterName)
  //   );

  //   setFilters({
  //     ...updatedObj,
  //     ...shortcodeFacets,
  //   });
  //   setPage(1);
  //   setUrlParams({
  //     ...updatedObj,
  //     ...shortcodeFacets,
  //     paged,
  //     limit: itemsPerPage,
  //   });
  // };

  // const handleResetFilter = () => {
  //   setFilters({
  //     ...shortcodeFacets,
  //   });
  //   // setSearchText("");
  //   setPage(1);
  //   setUrlParams({});
  // };

  // const hasActiveFilters = Object.values(filters).some((value) => value !== "");

  return (
    <>
      <div className="product-list-container alliance-plp">
        {loading ? (
          <Loader />
        ) : (
          <div className="alliance-plp-holder">
            <div
              className={`plp-top-holder ${
                pageName == "collection" && `collection-top-holder`
              }`}>
              <div className="top-pager">
                <h4
                  className="fl-heading plp-heading text-center"
                  role="heading">
                  <span class="fl-heading-text">{plpSubTitle}</span>
                </h4>
                {plpTitle && (
                  <h1
                    className="fl-heading plp-heading text-center"
                    role="heading">
                    <span class="fl-heading-text">{plpTitle}</span>
                  </h1>
                )}
                {pageName !== "collection" && (
                  <div className="text-center pagecount">
                    {totalProducts > 0 ? totalProducts + " Products" : ""}
                  </div>
                )}
              </div>
              <div className={`facet_filters ${isOpen ? "open" : ""}`}>
                {/* {hasActiveFilters ? (
                  <ResetFilters handleResetFilter={handleResetFilter} />
                ) : (
                  ""
                )}
                {hasActiveFilters ? (
                  <RemoveFilters
                    filters={filters}
                    handleRemoveFilter={handleRemoveFilter}
                  />
                ) : (
                  ""
                )} */}
                {plpFacets.includes("brand_facet") && (
                  <BrandFacet
                    handleFilterChange={handleFilterChange}
                    productBrands={productBrands}
                    selectedBrand={filters.brand_facet || ""}
                  />
                )}

                {plpFacets.includes("collection_facet") && (
                  <CollectionFacet
                    handleFilterChange={handleFilterChange}
                    productCollections={productCollections}
                    selectedCollection={filters.collection_facet || ""}
                  />
                )}

                {plpFacets.includes("color_facet") && (
                  <ColorFacet
                    handleFilterChange={handleFilterChange}
                    productColors={productColors}
                    selectedColor={filters.color_facet || ""}
                  />
                )}
                {pageName === "collection" && (
                  <div className="text-center pagecount">
                    {totalProducts > 0 ? totalProducts + " Products" : ""}
                  </div>
                )}
                {plpFacets.includes("style_facet") && (
                  <StyleFacet
                    handleFilterChange={handleFilterChange}
                    productStyle={productStyle}
                    selectedStyle={filters.style_facet || ""}
                  />
                )}

                {plpFacets.includes("species_facet") && (
                  <SpeciesFacet
                    handleFilterChange={handleFilterChange}
                    productSpecies={productSpecies}
                    selectedSpecies={filters.species_facet || ""}
                  />
                )}

                {plpFacets.includes("construction_facet") && (
                  <ConstructionFacet
                    handleFilterChange={handleFilterChange}
                    productConstructions={productConstructions}
                    selectedConstruction={filters.construction_facet || ""}
                  />
                )}

                {plpFacets.includes("installation_method") && (
                  <InstallationMethodFacet
                    handleFilterChange={handleFilterChange}
                    productInstallationMethods={productInstallationMethods}
                    selectedInstallationMethod={
                      filters.installation_method || ""
                    }
                  />
                )}

                {plpFacets.includes("look_facet") && (
                  <LookFacet
                    handleFilterChange={handleFilterChange}
                    productLook={productLook}
                    selectedLook={filters.look_facet || ""}
                  />
                )}

                {plpFacets.includes("material_facet") && (
                  <MaterialFacet
                    handleFilterChange={handleFilterChange}
                    productMaterial={productMaterial}
                    selectedMaterial={filters.material_facet || ""}
                  />
                )}

                {plpFacets.includes("shade_facet") && (
                  <ShadeFacet
                    handleFilterChange={handleFilterChange}
                    productShade={productShade}
                    selectedShade={filters.shade_facet || ""}
                  />
                )}
              </div>
            </div>
            <div className="products-list-holder">
              {Object.keys(products).length > 0 && (
                <div className="products-list-holder-inner">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 products-list ">
                      <div class="open_sidebar">
                        <a
                          class="fl-button"
                          onClick={toggleFlyer}
                          href="javascript:void(0)">
                          <span class="fl-button-text">Filter By</span>
                        </a>
                      </div>
                      <div className="product-plp-grid product-grid swatch facetwp-template1 alliance">
                        <div className="row product-row">
                          {displayedProducts.length > 0 ? (
                            displayedProducts.map((product, i) => (
                              <ProductLoop
                                key={i}
                                index={i}
                                products={product}
                                visibleProducts={visibleProducts}
                              />
                            ))
                          ) : (
                            <p>No products available.</p>
                          )}
                        </div>
                        {loading ? (
                          <p>Loading...</p>
                        ) : visibleProducts < totalProducts ? (
                          <button
                            onClick={() => loadMore(visibleProducts + 20)}
                            className="facetwp-load-more"
                            data-loading="Loading...">
                            LOAD MORE
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {/* <div className="col-lg-12 col-md-12 col-sm-12 bottom-pager">
                      <div className="page-info">
                        <div className="pagination_wrapper">
                          <ul>
                            {pageNumbers.map((number) => {
                              return (
                                <Pagination
                                  key={number}
                                  number={number}
                                  handlePagination={handlePagination}
                                  currentPage={paged}
                                />
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              )}
              {Object.keys(products).length == 0 && loading == false && (
                <div className="col-lg-12 col-md-12 col-sm-12 product-list-container">
                  <div className="col-lg-12 col-md-12 col-sm-12 ">
                    <h2>No Products Available</h2>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AllianceProducts;
