import React from "react";

export default function LookFacet({
  handleFilterChange,
  productLook,
  selectedLook,
}) {
  return (
    <div className="facet-wrap facet-display">
      <div className="facetwp-facet">
        <select
          value={selectedLook}
          onChange={(e) => {
            const selectedLook = e.target.value;
            handleFilterChange("look_facet", selectedLook);
          }}>
          <option value="">CHOOSE LOOK</option>
          {Object.keys(productLook).length > 0 &&
            Object.keys(productLook).map((look, i) => {
              if (productLook[look] > 0) {
                return (
                  <option key={i} value={look.toLowerCase()}>
                    {look} ({productLook[look]})
                  </option>
                );
              }
              return null;
            })}
        </select>
      </div>
    </div>
  );
}
