import React from "react";

export default function BrandFacet({
  handleFilterChange,
  productBrands,
  selectedBrand,
}) {
  return (
    <div className="facet-wrap facet-display">
      <div className="facetwp-facet">
        <select
          value={selectedBrand}
          onChange={(e) => {
            const selectedBrand = e.target.value;
            handleFilterChange("brand_facet", selectedBrand);
          }}>
          <option value="">CHOOSE BRAND</option>
          {Object.keys(productBrands).length > 0 &&
            Object.keys(productBrands).map((brand, i) => {
              if (brand && productBrands[brand] > 0) {
                return (
                  <option key={i} value={brand.toLowerCase()}>
                    {brand} ({productBrands[brand]})
                  </option>
                );
              }
              return null; // Skip brands with no products
            })}
        </select>
      </div>
    </div>
  );
}
