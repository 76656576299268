import React from "react";

export default function ConstructionFacet({
  handleFilterChange,
  productConstructions,
  selectedConstruction,
}) {
  return (
    <div className="facet-wrap facet-display">
      <div className="facetwp-facet">
        <select
          value={selectedConstruction} // Set the selected collection as the value
          onChange={(e) => {
            const selectedConstruction = e.target.value;
            handleFilterChange("construction_facet", selectedConstruction);
          }}>
          <option value="">CHOOSE CONSTRUCTION</option>
          {Object.keys(productConstructions).length > 0 &&
            Object.keys(productConstructions).map((construction, i) => {
              if (productConstructions[construction] > 0) {
                return (
                  <option key={i} value={construction.toLowerCase()}>
                    {construction} ({productConstructions[construction]})
                  </option>
                );
              }
              return null;
            })}
        </select>
      </div>
    </div>
  );
}
