import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Products from "./Products"; // Adjust the import path as needed
import ProductDetails from "./ProductDetails"; // Adjust the import path as needed
import SearchProduct from "./SearchProduct";
import PopularInStock from "./PopularInStock";
import FeaturedProducts from "./FeaturedProducts";
import ProductLoopRow from "./ProductLoopRow";
import AllianceProducts from "./AllianceProducts";
import AllianceProductDetails from "./AllianceProductDetails";
import "./index.css";

const pdplayout = window.wpProductFilter.siteInfo.pdplayout
  ? window.wpProductFilter.siteInfo.pdplayout
  : "default";
if (pdplayout == "alliance") {
  const App6 = () => (
    <Routes>
      <Route
        path="/:category?/products/:urlKey"
        element={<AllianceProductDetails />}
      />
    </Routes>
  );
  const App5 = () => (
    <Routes>
      <Route path="/:category?/products" element={<AllianceProducts />} />
      <Route
        path="/:category?/:type?collections/:collection"
        element={<AllianceProducts />}
      />
      {/* <Route
        path="/:category?/collections/:urlKey"
        element={<AllianceCollectionProducts />}
      /> */}
    </Routes>
  );

  if (document.getElementById("mm-alliance-list")) {
    const root = ReactDOM.createRoot(
      document.getElementById("mm-alliance-list")
    );
    root.render(
      <BrowserRouter>
        <App5 />
      </BrowserRouter>
    );
  } else if (document.getElementById("mm-product-details")) {
    const root = ReactDOM.createRoot(
      document.getElementById("mm-product-details")
    );
    root.render(
      <BrowserRouter>
        <App6 />
      </BrowserRouter>
    );
  } else if (document.getElementById("search-result-page")) {
    const root = ReactDOM.createRoot(
      document.getElementById("search-result-page")
    );
    root.render(<SearchProduct />);
  }
} else {
  const App = () => (
    <Routes>
      <Route
        path="/residential/:category?/:type?/products/:urlKey"
        element={<ProductDetails />}
      />
      <Route
        path="/services/shop-in-stock/:type/:urlKey"
        element={<ProductDetails />}
      />
      <Route
        path="/flooring/in-stock/:type?-products/:urlKey"
        element={<ProductDetails />}
      />
      <Route
        path="/:category?/:type?/products/:urlKey"
        element={<ProductDetails />}
      />
      <Route
        path="/:category?/:type?/browse/:urlKey"
        element={<ProductDetails />}
      />

      <Route
        path="/flooring-products/:type?/:products?-catalog/:urlKey"
        element={<ProductDetails />}
      />
      <Route
        path="/product-category/:type/:urlKey"
        element={<ProductDetails />}
      />
      <Route
        path="/in-stock-specials/:type/:urlKey"
        element={<ProductDetails />}
      />
      <Route
        path="/in-stock/:type?-products/:urlKey"
        element={<ProductDetails />}
      />
      <Route path="/in-stock/:type/:urlKey" element={<ProductDetails />} />
      <Route path="/:category?/:type?/products" element={<Products />} />
      <Route
        path="/:residential?/:category?/:type?/products"
        element={<Products />}
      />
      <Route path="/?s=:search" element={<SearchProduct />} />

      <Route path="/:category?/:type?/:instock?" element={<Products />} />

      <Route
        path="/:category?/:type?/:popularinstock?"
        element={<PopularInStock />}
      />
    </Routes>
  );

  const App2 = () => (
    <Routes>
      <Route
        path="/:category?/:type?/:popularinstock?"
        element={<PopularInStock />}
      />
    </Routes>
  );

  const App3 = () => (
    <Routes>
      <Route
        path="/:category?/:type?/:featuredProducts?"
        element={<FeaturedProducts />}
      />
    </Routes>
  );
  const App4 = () => (
    <Routes>
      <Route
        path="/:category?/:type?/:product_loop?"
        element={<ProductLoopRow />}
      />
    </Routes>
  );
  //const rootElement = document.getElementById("mm-product-list") || document.getElementById("mm-product-details");

  if (document.getElementById("mm-product-list")) {
    const root = ReactDOM.createRoot(
      document.getElementById("mm-product-list")
    );
    root.render(
      <BrowserRouter>
        <App />
      </BrowserRouter>
    );
  } else if (document.getElementById("mm-product-details")) {
    const root = ReactDOM.createRoot(
      document.getElementById("mm-product-details")
    );
    root.render(
      <BrowserRouter>
        <App />
      </BrowserRouter>
    );
  } else if (document.getElementById("search-result-page")) {
    const root = ReactDOM.createRoot(
      document.getElementById("search-result-page")
    );
    root.render(<SearchProduct />);
  }

  if (document.getElementById("mm-popular-in-stock")) {
    const root = ReactDOM.createRoot(
      document.getElementById("mm-popular-in-stock")
    );
    root.render(
      <BrowserRouter>
        <App2 />
      </BrowserRouter>
    );
  }
  if (document.getElementById("mm-featured-products")) {
    const root = ReactDOM.createRoot(
      document.getElementById("mm-featured-products")
    );
    root.render(
      <BrowserRouter>
        <App3 />
      </BrowserRouter>
    );
  }
  if (document.getElementById("mm-product-loop")) {
    const root = ReactDOM.createRoot(
      document.getElementById("mm-product-loop")
    );
    root.render(
      <BrowserRouter>
        <App4 />
      </BrowserRouter>
    );
  }
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
