import React from "react";

export default function ShadeFacet({
  handleFilterChange,
  productShade,
  selectedShade,
}) {
  return (
    <div className="facet-wrap facet-display">
      <div className="facetwp-facet">
        <select
          value={selectedShade}
          onChange={(e) => {
            const selectedShade = e.target.value;
            handleFilterChange("shade_facet", selectedShade);
          }}>
          <option value="">CHOOSE SHADE</option>
          {Object.keys(productShade).length > 0 &&
            Object.keys(productShade).map((shade, i) => {
              if (productShade[shade] > 0) {
                return (
                  <option key={i} value={shade.toLowerCase()}>
                    {shade} ({productShade[shade]})
                  </option>
                );
              }
              return null;
            })}
        </select>
      </div>
    </div>
  );
}
