import React from "react";

export default function CollectionFacet({
  handleFilterClick,
  productCollections,
  handleCheckboxChange,
  selectedCollection,
}) {
  // console.log(selectedCollection);
  // selectedCollection = selectedCollection ? selectedCollection.split(",") : [];
  return (
    <div class="facet-wrap facet-display">
      <strong>Collection</strong>
      <div className="facetwp-facet">
        {Object.keys(productCollections).map((collection, i) => {
          if (collection && productCollections[collection] > 0) {
            return (
              <div>
                {/* <label>
                  <input
                    id={`collection-filter-${i}`}
                    key={i}
                    data-value={`${collection.toLowerCase()}`}
                    type="checkbox"
                    checked={
                      selectedCollection &&
                      selectedCollection.includes(collection.toLowerCase())
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      handleCheckboxChange(
                        "collection_facet",
                        e.target.dataset.value
                      )
                    }
                  />
                  {collection} {` (${productCollections[collection]}) `}
                </label> */}
                <span
                  id={`collection-filter-${i}`}
                  key={i}
                  data-value={`${collection.toLowerCase()}`}
                  onClick={(e) =>
                    handleFilterClick(
                      "collection_facet",
                      e.target.dataset.value
                    )
                  }>
                  {" "}
                  {collection} {` (${productCollections[collection]}) `}
                </span>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}
