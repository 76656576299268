import React, { useState } from "react";
import popupOpener from "./media/popup_opener.png";
import PdpGalleryPopup from "./PdpGalleryPopup";

export default function GalleryImagesGrid({
  product,
  swatch,
  gallery_images,
  width,
  height,
}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const manufacturerBrands = ["Bruce", "AHF", "Shaw"];
  var productManufacturer = manufacturerBrands.includes(product.manufacturer)
    ? product.brand.toLowerCase()
    : product.manufacturer.toLowerCase();
  var productManufacturer = productManufacturer.replace(/\s/g, "");
  var sku = product.sku;
  const [displayPopup, setDisplayPopup] = useState("hide");

  const handleClick = (index) => {
    setActiveIndex(index); // Set the active index
  };

  const populatePopup = (status) => {
    setDisplayPopup(status);
  };

  let swatchimg = `https://mm-media-res.cloudinary.com/image/fetch/h_${height},w_${width},c_limit/https://`;
  let swatchFull = swatchimg + swatch;
  // var settings = {
  //   dots: !1,
  //   infinite: !1,
  //   arrows: !0,
  //   slidesToShow: 4,
  //   slidesToScroll: 2,
  //   mobileFirst: !0,
  //   responsive: [
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 3,
  //       },
  //     },
  //   ],
  // };

  return (
    <div class="toggle-image-thumbnails ">
      <div
        // class="toggle-image-holder active"
        //onClick={() => handleClick(0)}
        className={
          activeIndex === 0
            ? "toggle-image-holder active"
            : "toggle-image-holder"
        }
        key={0}>
        <a
          href="javascript:void(0)"
          style={{
            backgroundImage: `url(${swatchimg}${swatch})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          onClick={(e) => populatePopup("show")}
          //data-targetimg={`gallery_item_0`}
          //data-background={`${swatchimg}${swatch}`}
          //data-fr-replace-bg=".toggle-image"
        >
          <img
            key="0"
            src={`${swatchimg}${swatch}`}
            width={width}
            height={height}
          />
        </a>
        <div id="roomvo">
          <div className="roomvo-container">
            <a
              className="roomvo-stimr button"
              data-sku={product.sku}
              style={{ visibility: "hidden" }}>
              <i className="fa fa-camera" aria-hidden="true"></i> &nbsp;See In
              My Room
            </a>
          </div>
        </div>
      </div>
      {gallery_images &&
        gallery_images.split("|").map((image, index) => (
          <div
            onClick={() => handleClick(index + 1)}
            className={
              activeIndex === index + 1
                ? "toggle-image-holder active"
                : "toggle-image-holder"
            }
            key={index + 1}>
            <a
              href="javascript:void(0)"
              style={{
                backgroundImage: `url(${swatchimg}${image})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
              onClick={(e) => populatePopup("show")}
              // data-targetimg={`gallery_item_${index + 1}`}
              // data-background={`${swatchimg}${image}`}
              // data-fr-replace-bg=".toggle-image"
            >
              <img src={`${swatchimg}${image}`} width={width} height={height} />
            </a>
          </div>
        ))}

      <div class="image-expander">
        <img
          alt="zoom-img"
          class="img"
          src={popupOpener}
          onClick={(e) => populatePopup("show")}
        />
      </div>

      <script type="text/javascript">
        {`       
                        function getProductSKU() {                       
                          return "${productManufacturer}-${sku.toLowerCase()}";
                        } 
                      `}
      </script>
      <div className={`pdpGalleryImagePopup ${displayPopup}`}>
        <div className="closePopup">
          <span onClick={(e) => populatePopup("hide")}>X</span>
        </div>
        <PdpGalleryPopup
          swatch={swatch}
          galleryImages={gallery_images}
          height={800}
          width={800}
          //productName={`${product.name} ${product.sku}`}
        />
      </div>
    </div>
  );
}
