import React from "react";
import GalleryImagesGrid from "./GalleryImagesGrid";

export default function ProductGalleryRow({ product }) {
  return (
    <div>
      <GalleryImagesGrid
        product={product}
        swatch={product.swatch}
        gallery_images={product.gallery_images}
        //populatePopup={populatePopup}
        height={800}
        width={800}
      />
    </div>
  );
}
