import React, { useState, useEffect } from "react";
// import SwatchImagesPDP from "./SwatchImagesPdp";
import ProductAttributes from "./ProductAttributes";
// import ColorSliderPdp from "./ColorSliderPdp";
import "../Products.css";
import ProductBrandLogos from "./ProductBrandLogos";
import ProductGallerySlider from "./ProductGallerySlider";
import ProductGalleryRow from "./ProductGalleryRow";
import arrow_left from "./media/arrow_left.png";
import arrow_right from "./media/arrow_right.png";
// import instock_mark from "./media/instock_mark.png";

// import PdpGalleryPopup from "./PdpGalleryPopup";
// import SizeAvailables from "./SizeAvailables";

export default function AllianceProductDetailPage({ product, type, sku }) {
  const [products, setProducts] = useState([]);
  const [sizeProducts, setSizeProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [displayPopup, setDisplayPopup] = useState("hide");
  const wpProductFilter = window.wpProductFilter;
  const wpProductCategory = window.wpProductCategory;
  const show_size_on_pdp = wpProductCategory.show_size_on_pdp
    ? wpProductCategory.show_size_on_pdp
    : "";
  const brand_collection = product.brand_collection;
  const inStock =
    product.in_stock !== "" || product.in_stock !== undefined
      ? product.in_stock
      : "";
  const siteInfo = window.wpProductFilter.siteInfo;
  const getcouponbtn = siteInfo.getcouponbtn;
  const getcouponreplace = siteInfo.getcouponreplace;
  const getcouponreplacetext = siteInfo.getcouponreplacetext;
  const getcouponreplaceurl = siteInfo.getcouponreplaceurl;
  const show_financing = siteInfo.show_financing;
  const pdp_get_finance = siteInfo.pdp_get_finance;
  const getfinancereplace = siteInfo.getfinancereplace;
  const getcoupon_link = siteInfo.getcoupon_link;
  const showprice = siteInfo.plpshowprice;
  const showrugsbutton = siteInfo.showrugsbutton;
  const rugsbrands = siteInfo.rugsbrands;
  const currentPageID = siteInfo.currentPageID;
  const arearugshowsku = siteInfo.arearugshowsku;
  const rugpageurl = siteInfo.rugpageurl + "?id=" + currentPageID;

  const getfinancereplaceurl =
    getfinancereplace === "1"
      ? siteInfo.getfinancereplaceurl
      : "/flooring-financing/";
  const getfinancetext =
    getfinancereplace === "1" ? siteInfo.getfinancetext : "Financing";
  const manufacturerBrands = ["Bruce", "AHF", "Shaw"];
  var productManufacturer = manufacturerBrands.includes(product.manufacturer)
    ? product.brand.toLowerCase()
    : product.manufacturer.toLowerCase();
  var productManufacturer = productManufacturer.replace(/\s/g, "");

  useEffect(() => {
    fetchProducts();
    if (show_size_on_pdp === "yes") {
      fetchSizeWiseProducts();
    }
  }, [product]);

  const jsonld = {
    "@context": "https://schema.org/",
    "@type": "Product",
    name: product.name + " " + product.sku,
    image: `https://mm-media-res.cloudinary.com/image/fetch/h_800,w_800,c_limit/https://${product.swatch}`,
    description: product.name + " " + product.sku,
    sku: product.sku,
    mpn: product.sku,
    brand: {
      "@type": "thing",
      name: product.brand,
    },
    offers: {
      "@type": "offer",
      priceCurrency: "USD",
      price: "00",
      priceValidUntil: "",
    },
  };

  const fetchProducts = async () => {
    const query = new URLSearchParams({
      brand_collection: brand_collection,
      category: type,
      in_stock: inStock,
      current_sku: product.sku,
    });

    try {
      const response = await fetch(`${wpProductFilter.apiEndpoint}?${query}`);
      const data = await response.json();
      setProducts(data.products_group_by);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchSizeWiseProducts = async () => {
    const query1 = new URLSearchParams({
      brand_collection: brand_collection,
      category: type,
      in_stock: inStock,
      show_size_on_pdp: show_size_on_pdp,
      color: product.color,
    });

    try {
      const response1 = await fetch(`${wpProductFilter.apiEndpoint}?${query1}`);
      const data1 = await response1.json();
      setSizeProducts(data1.products_group_by);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };
  const populatePopup = (status) => {
    setDisplayPopup(status);
  };

  var wpProductCategoryURL = "/" + wpProductCategory.rewrite_slug + "/";
  var wpProductCollection =
    wpProductCategoryURL +
    "/?brand_facet=" +
    product.brand.toLowerCase().replace(" ", "+") +
    "&collection_facet=" +
    product.collection_facet.toLowerCase().replace(" ", "+");

  let productdata = products[Object.keys(products)[0]];
  var prevpageurl, nextpageurl;
  {
    productdata?.map((singleproduct, index) => {
      if (singleproduct.sku == product.sku) {
        if (index == 0) {
          var getCount = productdata.length - 1;
        } else {
          var getCount = index - 1;
        }
        const prevobjsku = productdata[getCount];

        prevpageurl = prevobjsku.url_key;
        prevpageurl = prevpageurl.split("products/");
        prevpageurl = wpProductCategoryURL + "/" + prevpageurl[1] + "/";

        if (index == productdata.length - 1) {
          var getCount1 = 0;
        } else {
          var getCount1 = index + 1;
        }
        const nextobjsku = productdata[getCount1];
        nextpageurl = nextobjsku.url_key;
        nextpageurl = nextpageurl.split("products/");
        nextpageurl = wpProductCategoryURL + "/" + nextpageurl[1] + "/";
      }
    });
  }

  return (
    <div className="product_detail_wrap">
      <div className="row">
        <div class="col-md-12 col-sm-12">
          <h1 class="pdp_heading_text">
            {product.brand} {product.collection_name} <br /> {product.design}{" "}
            {product.color}
          </h1>
        </div>
      </div>
      <div className="row" id="plp_content_wrap">
        <div className="col-md-7 col-sm-12 product-swatch">
          {/* <div className="imagesHolder">
            <SwatchImagesPDP
              swatch={product.swatch}
              populatePopup={populatePopup}
              height={600}
              width={600}
            />

            <div class="image-expander">
              <img
                alt="zoom-img"
                class="img"
                src={popupOpener}
                onClick={(e) => populatePopup("show")}
              />
            </div>
          </div> */}
          <div className="product-gallery">
            <ProductGalleryRow product={product} />
          </div>
        </div>
        <div className="col-md-5 col-sm-12 product-box">
          <div class="pdp_right_content" id="plp_content_sidebar">
            <div class="plp_content_sidebar_inner">
              <div class="top_slider">
                <a href={prevpageurl} class="arrow_left">
                  <img src={arrow_left} alt="Prev Product" />
                </a>
                <a href={nextpageurl} class="arrow_right">
                  <img src={arrow_right} alt="Next Product" />
                </a>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="pdp_right_content_top">
                    <h2 class="fl-post-title custom">{product.brand}</h2>
                    <h2 class="fl-post-title">{product.color}</h2>
                    <h4 class="color_code">color#: {product.color}</h4>

                    <div class="pdp_cat_collection">
                      <div class="pdp_cat">
                        <span>CATEGORY:</span>
                        <div class="post_typetext">
                          <a href={wpProductCategoryURL}>
                            <span>
                              {wpProductCategory.category === "lvt"
                                ? "LUXURY VINYL TILE"
                                : wpProductCategory.category}
                            </span>
                          </a>
                        </div>
                      </div>
                      <div class="pdp_collection">
                        <span>COLLECTION</span>

                        <div class="post_typetext">
                          <a href={wpProductCollection}>
                            <span>{product.collection_name}</span>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="note">
                      <p>
                        *Availability varies by store. These products represent
                        a small portion of what we have to offer in our store,
                        please call or visit store for details.
                      </p>
                    </div>

                    <div class="colorstile_logo">
                      <ProductBrandLogos product={product} />
                    </div>

                    {inStock == 1 && showprice == 1 && product.price != 0 && (
                      <div className="price">
                        <strong>
                          <sup>$</sup>
                          {product.price}
                        </strong>
                        &nbsp;/<sub>{product.price_unit}</sub>
                      </div>
                    )}
                    <div classname="see_in_my_room">
                      <div classname="roomvo">
                        <div className="roomvo-container">
                          <a
                            className="roomvo-stimr button"
                            data-sku={product.sku}
                            style={{ visibility: "hidden" }}>
                            <i className="fa fa-camera" aria-hidden="true"></i>{" "}
                            &nbsp;See In My Room
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div class=" col-md-6 text-right">
                  <ProductBrandLogos product={product} />
                  {product.in_stock == 1 && (
                    <div class="col-lg-12 MarkInstock">
                      <img
                        src={instock_mark}
                        className="Instock-mark"
                        alt="Instock-Mark"
                      />
                      <span>IN-STOCK</span>
                    </div>
                  )}
                </div> */}
              </div>
              <div class="pdp_right_content_middle">
                <div className="button-wrapper">
                  <a href="/contact-us/" className="button contact-btn">
                    Contact Us
                  </a>

                  {(pdp_get_finance != 1 || pdp_get_finance == "") && (
                    <a
                      href={getfinancereplaceurl}
                      className="finance-btn button">
                      {getfinancetext}
                    </a>
                  )}

                  {getcouponbtn === "1" ? (
                    <a
                      href={getcoupon_link}
                      className="button alt getcoupon-btn">
                      <span className="fl-button-text">Get Coupon</span>
                    </a>
                  ) : (
                    ""
                  )}

                  {getcouponreplace === "1" &&
                  (getcouponreplaceurl !== undefined && getcouponreplaceurl) !==
                    "" &&
                  getcouponreplacetext !== "" &&
                  getcouponreplacetext !== undefined ? (
                    <a
                      href={getcouponreplaceurl}
                      className="button alt custompdpbtn getcoupon-btn">
                      {getcouponreplacetext}
                    </a>
                  ) : (
                    ""
                  )}

                  {showrugsbutton === "1" &&
                  rugsbrands.indexOf(product.brand) !== -1 ? (
                    <a href={rugpageurl} class="floorvana-btn button">
                      Create A Rug
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div class="pdp_right_content_bottom">
                <div
                  class="panel-group"
                  id="accordion"
                  role="tablist"
                  aria-multiselectable="true">
                  <div class="panel panel-default">
                    <div class="panel-heading" role="tab" id="headingOne">
                      <h4 class="panel-title">
                        <a
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapseOne"
                          aria-expanded="false"
                          className="collapsed"
                          aria-controls="collapseOne">
                          <i class="accordion_icon fa fa-plus"></i> PRODUCT
                          SPECIFICATIONS
                        </a>
                      </h4>
                    </div>
                    <div
                      id="collapseOne"
                      class="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="headingOne">
                      <div class="panel-body">
                        <div id="product-attributes-wrap">
                          <ProductAttributes product={product} />
                        </div>
                      </div>
                    </div>
                  </div>

                  {product.description && (
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab" id="headingTwo">
                        <h4 class="panel-title">
                          <a
                            class="collapsed"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo">
                            <i class="accordion_icon fa fa-plus"></i> PRODUCT
                            DESCRIPTION
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapseTwo"
                        class="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo">
                        <div class="panel-body">
                          <div id="product-attributes-wrap">
                            {product.description}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className={`pdpGalleryImagePopup ${displayPopup}`}> */}

      <script type="application/ld+json">{JSON.stringify(jsonld)}</script>
      <script typ="text/javascript">
        {`
        jQuery(document).ready(function(){
          window.setTimeout(function(){
            if(jQuery('.pdp_right_content').length > 0){
              jQuery('.pdp_right_content').theiaStickySidebar({
                containerSelector: '#plp_content_wrap',
                additionalMarginTop: 230
              });
            }
          }, 1000);
        });
      `}
      </script>
    </div>
  );
}
