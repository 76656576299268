import React from "react";

export default function CollectionFacet({
  handleFilterChange,
  productCollections,
  selectedCollection,
}) {
  return (
    <div className="facet-wrap facet-display">
      <div className="facetwp-facet">
        <select
          value={selectedCollection} // Set the selected collection as the value
          onChange={(e) => {
            const selectedCollection = e.target.value;
            handleFilterChange("collection_facet", selectedCollection);
          }}>
          <option value="">CHOOSE COLLECTION</option>
          {Object.keys(productCollections).length > 0 &&
            Object.keys(productCollections).map((collection, i) => {
              if (productCollections[collection] > 0) {
                return (
                  <option key={i} value={collection.toLowerCase()}>
                    {collection} ({productCollections[collection]})
                  </option>
                );
              }
              return null;
            })}
        </select>
      </div>
    </div>
  );
}
