import React from "react";

export default function ColorFacet({ handleFilterChange, productColors, selectedColor }) {
// console.log(productColors);
  return (
    <div className="facet-wrap facet-display">
      <div className="facetwp-facet">
        <select
        value={selectedColor}
          onChange={(e) => {
            const selectedColor = e.target.value;
            if (handleFilterChange && typeof handleFilterChange === 'function') {
              handleFilterChange("color_facet", selectedColor);  // Ensure handleFilterChange is a function
            } else {
              console.error("handleFilterChange is not a function");
            }
          }}
        >
          <option value="">CHOOSE COLOR</option>
          {Object.keys(productColors).map((color, i) => {
            if (color && productColors[color] > 0) {
              return (
                <option key={i} value={color.toLowerCase()}>
                  {color} ({productColors[color]})
                </option>
              );
            }
            return null; // Skip colors with no products
          })}
        </select>
      </div>
    </div>
  );
}
